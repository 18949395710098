import React, { createContext, useContext, useState, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { useMessage } from './message-provider'

const AuthContext = createContext({})

function AuthProvider({ children }) {
  const { addMessage } = useMessage()

  const [state, setState] = useState(() => {
    const user = firebase.auth().currentUser
    return { initializing: !user, user }
  })

  function onChange(user) {
    setState({ initializing: false, user })
  }

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  const login = async (email, password) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      return Promise.resolve('yay!')
    } catch (error) {
      addMessage(error.message)
      return Promise.reject(error)
    }
  }

  const logout = () => {
    console.log('Signing out user')
    firebase.auth().signOut()
  }

  // if (loading) {
  //   return <>Loading...</>
  // }

  return (
    <AuthContext.Provider value={{ user: state.user, initializing: state.initializing, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

// Nice - don't have to call useContext everywhere
const useAuth = () => useContext(AuthContext)

export { useAuth, AuthProvider }
