/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'tailwindcss/dist/base.min.css'

import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import tw, { css } from 'twin.macro'
import { Global } from '@emotion/core'
import { theme } from './src/components/theme'

import { MessageProvider } from './src/context/message-provider'
import { AuthProvider } from './src/context/auth-provider'
import Messenger from './src/components/Messenger'

if (process.env.NODE_ENV === 'development') {
  firebase.app().functions().useFunctionsEmulator('http://localhost:5001')
}

export const wrapRootElement = ({ element }) => (
  <MessageProvider>
    <Global
      styles={css`
        ${theme}
      `}
    />
    <Messenger />
    <AuthProvider>{element}</AuthProvider>
  </MessageProvider>
)
