import React from 'react'
import tw, { css } from 'twin.macro'

import { useMessage } from '../context/message-provider'

const container = css`
  ${tw`w-screen h-screen fixed z-50 bg-gray-100 p-4 flex flex-col justify-center`}
  backdrop-filter: blur(2px);
  --bg-opacity: 0.9;
`

const Messenger = () => {
  const { messages, clearMessages } = useMessage()

  // No messages? no render
  if (!messages.length) return null

  return (
    <div css={container}>
      <div tw="relative mx-auto shadow-xl bg-white w-full p-8" css={{ maxWidth: '500px' }}>
        <button
          type="button"
          onClick={clearMessages}
          tw="absolute right-0 top-0 text-4xl px-4 py-2 text-purple transform hocus:scale-110 transition duration-150 hocus:text-purple-light"
        >
          ×
        </button>
        {messages.map((message, key) => (
          <p key={key} tw="font-bold text-center">
            {message}
          </p>
        ))}
      </div>
    </div>
  )
}

export default Messenger
