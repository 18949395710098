import tw, { css } from 'twin.macro'

export const theme = css`
  html {
    opacity: 0;
    transform: opacity 0.1s ease;
    &.wf-active {
      opacity: 1;
    }
  }
  body {
    ${tw`font-sans antialiased text-default text-base leading-none`}
  }
  p {
    ${tw`leading-tight mb-4`}
    &:last-of-type {
      ${tw`mb-0`}
    }

    a {
      ${tw`underline`}
    }
  }

  .full-day,
  .before-after,
  .before,
  .after {
    ${tw`inline-block bg-purple text-white rounded-full w-auto px-3 text-center`}
    font-size: 0.85rem;
  }

  .before {
    background: #4db4dc;
  }
  .after {
    background: #d7b928;
  }
`
