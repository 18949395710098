import React from "react"
import { ThemeProvider } from "emotion-theming"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../tailwind.config"

const Theme = props => {
  // Load in the tailwind theme values
  const { theme } = resolveConfig(tailwindConfig)

  // Add some helper methods
  theme.mq = bp => `@media (min-width: ${theme.screens[bp]})`
  // console.log(props)
  return <ThemeProvider {...props} {...{ theme }} />
}

export default Theme
